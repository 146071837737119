import React, { useContext, useEffect, useState } from "react";
import Styles from "./ServiceSelectionDropdown.module.scss";

// Components
import Chip from "@vahak/core-ui/dist/components/Chip";
import { Dropdown } from "@vahak/core/dist/components";
import AppImg from "../../../utils/AppImg";
import Typography from "@vahak/core-ui/dist/components/Typography";
import PostingChip from "../../../posting-form/common/posting-chip/PostingChip";
// Constants
import { CompanyServiceType } from "@vahak/core/dist/_services";
import { ServiceTypeOptions } from "../../../auth/service-type-selection/ServiceTypeSelection";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { AppContext } from "@vahak/core/dist/app-context";
// Methods
import classnames from "classnames";
// Hooks
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
// Assets
import ArrowIcon from "@vahak/core/dist/standard-icons/down_arrow.svg";

interface ServiceSelectionDropdownProps {}
const ServiceSelectionDropdown = ({}: ServiceSelectionDropdownProps) => {
    const modalState = useToggleModalWithLocalState();
    const { serviceType, setServiceType } = useContext(AppContext);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState<string>(
        serviceType ?? CompanyServiceType.TRANSPORT_CONTRACTOR
    );
    const serviceOption = ServiceTypeOptions?.find((s) => s.value === selectedServiceTypes);

    const handleServiceTypeSelection = (value: typeof selectedServiceTypes) => {
        setSelectedServiceTypes(value);
        setServiceType?.(value);
        modalState?.closeModal();
    };

    useEffect(() => {
        document?.addEventListener("scroll", modalState?.closeModal);
        return () => {
            document?.removeEventListener("scroll", modalState?.closeModal);
        };
    }, []);

    return (
        <Dropdown
            isOpen={modalState?.isModalOpen}
            handleToggle={modalState?.toggleModal}
            DropdownToggleBtn={
                <Chip radius={60} size="auto" outlined outlineColor={COLORS.GREY_200} padded={false} color="grey">
                    <div className={Styles.dropdownBtn}>
                        {serviceOption?.membershipCategoryIconName && (
                            <AppImg
                                src={serviceOption?.membershipCategoryIconName}
                                unoptimized
                                height={24}
                                width={60}
                            />
                        )}
                        <ArrowIcon />
                    </div>
                </Chip>
            }
        >
            <div className={Styles.main}>
                <div className={Styles.list}>
                    {ServiceTypeOptions.map((service, index) => {
                        const { membershipCategoryIconName, membershipCategoryActiveIconName } = service;
                        const isActive = selectedServiceTypes === service.value;
                        return (
                            <PostingChip
                                key={index}
                                radius={6}
                                size="auto"
                                padded={false}
                                onClick={handleServiceTypeSelection.bind(undefined, service.value)}
                                active={isActive}
                            >
                                <div className={classnames(Styles.card, true, isActive && Styles.active)} key={index}>
                                    <div className={Styles.cardImg}>
                                        <AppImg
                                            src={
                                                isActive ? membershipCategoryActiveIconName : membershipCategoryIconName
                                            }
                                            unoptimized
                                            height={32}
                                            width={80}
                                        />
                                    </div>
                                    <Typography weight="medium" size="sm" color={COLORS.BLACK}>
                                        I am {service?.shortLabel}
                                    </Typography>
                                </div>
                            </PostingChip>
                        );
                    })}
                </div>
            </div>
        </Dropdown>
    );
};

export default ServiceSelectionDropdown;
